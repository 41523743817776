import { Lightning, Utils } from '@lightningjs/sdk'

export default class App extends Lightning.Component {
  static getFonts() {
    return [{ family: 'Regular', url: Utils.asset('fonts/Roboto-Regular.ttf') }]
  }

  static _template() {
    return {
      // Background: {
      //   w: 1920,
      //   h: 1080,
      //   color: 0xfffbb03b,
      //   src: Utils.asset('images/background.png'),
      // },
      MainContainer: {
        w: 1920,
        h: 1080,
        rect: true,
        color:0xff000000
      },
      Text: {
        mount: 0.5,
        x: 960,
        y: 720,
        text: {
          text: "LightningJS!!",
          fontFace: 'Regular',
          fontSize: 64,
          textColor: 0xbbffffff,
        },
      },
    }
  }

  _init() {
    // this.tag('Background')
    //   .animation({
    //     duration: 15,
    //     repeat: -1,
    //     actions: [
    //       {
    //         t: '',
    //         p: 'color',
    //         v: { 0: { v: 0xfffbb03b }, 0.5: { v: 0xfff46730 }, 0.8: { v: 0xfffbb03b } },
    //       },
    //     ],
    //   })
    //   .start()
  }
}

// import { Lightning, Utils } from '@lightningjs/sdk'

// export default class App extends Lightning.Component {
//   static getFonts() {
//     return [{ family: 'Regular', url: Utils.asset('fonts/Roboto-Regular.ttf') }]
//   }

//   static _template() {
//     return {
//       // This is the main div where text will be placed
//       MainContainer: {
//         w: 1920,
//         h: 1080,
//         // Center the text inside the container
//         flex: {
//           direction: 'column',
//           alignItems: 'center',
//           justifyContent: 'center',
//         },
//         Text: {
//           text: {
//             text: "Let's start Building!",
//             fontFace: 'Regular',
//             fontSize: 64,
//             textColor: 0xbb000000,
//           },
//         },
//       },
//     }
//   }
// }
